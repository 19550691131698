import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDebounce } from "use-debounce";
import moment from "moment";
import HelpRequestService from "../../service/helpRequest.service";
import ProgressBar from "../../components/ProgressBar";
import AvatarIcon from "../../components/Avar";
import Loader from "../../assets/images/loader.gif";
import { useNavigate } from "react-router-dom";
import UserTypeDropdown from "../../components/UserType";
const HelpRequests = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [value] = useDebounce(searchText, 1000);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [data, setData] = useState([]);
  const [userType, setUserType] = useState("User"); // Default value is "User"

  const handleUserTypeChange = (selectedType) => {
    setUserType(selectedType);
    setPage(1);
    console.log("Selected User Type:", selectedType);
  };

  const users = [
    { label: "User", value: "User" },
    { label: "Vendor", value: "Vendor" },
  ];
  const NumberWithCommas = (number) => {
    return number?.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };
  const showMessage = (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  const getAllHelpRequest = async () => {
    try {
      setIsLoadMoreLoading(true);
      if (page === 1) {
        setData([]); // Reset the data state to an empty array
      }
      const response = await HelpRequestService?.getAllHelp(
        page,
        10,
        value,
        userType
      );
      if (response) {
        setData(
          (prevData) =>
            page === 1
              ? response?.data?.data // Replace data for the first page
              : [...prevData, ...response?.data?.data] // Append data for subsequent pages
        );
        setTotalCount(response.data.total);
      }
    } catch (error) {
      showMessage(error?.response?.data?.message, "error");
      console.log("🚀  getAllHelpRequest  error:", error);
    } finally {
      setIsLoadMoreLoading(false);
    }
  };

  useEffect(() => {
    getAllHelpRequest();
  }, [page, value, userType]);
  return (
    <div>
      {/* {isLoadMoreLoading ? (
        <div className="flex justify-center items-center h-screen ">
          {" "}
          <img src={Loader} alt="Loader" className="w-12 h-12 " />
        </div>
      ) : ( */}
      <div>
        <div>
          <div className="flex justify-between items-center">
            <h4 className="text-xs sm:text-[26px] py-4 px-2">
              Explore All Active Help Requests...
            </h4>
            <div className="flex items-center gap-4">
              <UserTypeDropdown
                options={users}
                value={userType}
                onChange={handleUserTypeChange}
              />
              <input
                className=" border border-primary/15 w-[300px] rounded-lg py-3 ps-3 pe-8  text-sm focus:outline-none focus:ring-[0.4px] focus:ring-primary h-10 overflow-hidden placeholder:text-primary/70"
                placeholder="Search Help Requests"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <hr className="mb-10 mt-3" />
        </div>
        <div className="grid grid-cols-1 tab:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {data?.length > 0 &&
            data?.map((res, index) => {
              return (
                <div
                  key={index}
                  className="p-4 rounded-xl cursor-pointer border border-s-info-light "
                  onClick={() =>
                    navigate(`/helprequests/${res?._id}/${userType}`, {
                      state: {
                        id: res?._id,
                        type: userType,
                        additionalInfo: res, // Add any additional state you want to pass
                      },
                    })
                  }
                >
                  {/* Header Section */}
                  <div className="border-b border-primary/20 pb-[14px] flex items-center gap-2.5">
                    {res?.categoryId?.name && (
                      <span className="py-1 px-2 bg-blue-100 rounded">
                        <strong className="text-blue-600">
                          {res?.categoryId?.name}
                        </strong>
                      </span>
                    )}
                    <h6 className="text-sm truncate">
                      {res?.vendorId?.company}
                    </h6>
                  </div>

                  {/* User Details */}
                  <div className="py-[14px] flex items-start justify-between">
                    <div className="flex gap-3">
                      {/* Profile Image */}
                      <div className="h-[60px] w-[60px] sm:h-[75px] sm:w-[75px] md:h-20 md:w-20 rounded-lg overflow-hidden avatar">
                        {res.userId?.latestProfileImage ? (
                          <img
                            src={res.userId?.latestProfileImage}
                            alt="User"
                            className="object-cover w-full h-full"
                          />
                        ) : (
                          <AvatarIcon
                            size={60}
                            name={res.customerName}
                            className="rounded-lg overflow-hidden"
                          />
                        )}
                      </div>

                      {/* User Info */}
                      <div className="flex flex-col justify-between">
                        <div className="flex flex-col">
                          <h6 className="font-medium leading-snug truncate max-w-[150px] md:max-w-[200px]">
                            {res?.customerName}
                          </h6>
                          <strong className="text-primary/60 text-sm">
                            Updated:{" "}
                            {moment(res?.updatedAt).format("DD MMM YYYY")}
                          </strong>
                        </div>
                      </div>
                    </div>

                    {/* Payyro Verification */}
                    {res?.userId?.isPayyroVerified && (
                      <div className="flex items-center gap-1">
                        <h6 className="text-10 md:text-xs text-success leading-none">
                          Payyro Verified
                        </h6>
                      </div>
                    )}
                  </div>

                  {/* Progress Bar */}
                  <ProgressBar user={res} />

                  {/* Funding Details */}
                  <div className="pt-[14px] flex items-center justify-between gap-4">
                    <span className="text-xs font-bold">
                      $
                      {res?.fundingAmount
                        ? NumberWithCommas(res?.fundingAmount)
                        : 0}{" "}
                      funded
                    </span>
                    <span className="text-xs font-bold">
                      ${NumberWithCommas(res?.requestedAmount)} requested
                    </span>
                  </div>

                  {/* Address */}
                  <div className="pt-[14px] md:hidden">
                    <p className="line-clamp-2">{res?.address}</p>
                  </div>
                </div>
              );
            })}
        </div>
        {data.length === 0 && (
          <div className="flex justify-center items-center h-[calc(100vh-278px)] text-2xl">
            Help requests not found
          </div>
        )}
      </div>
      {/* )} */}
      {totalCount > data.length && (
        <div className="flex justify-center items-center h-full mt-6">
          {isLoadMoreLoading ? (
            <img src={Loader} alt="Loader" className="w-12 h-12" />
          ) : (
            <button
              className="px-3 py-2 border border-dark rounded-lg hover:text-white hover:bg-dark text-base"
              type="button"
              onClick={() => setPage(page + 1)}
            >
              Load More
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default HelpRequests;
