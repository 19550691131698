import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdEdit } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import UserService from "../service/user.service";
import Loader from "../assets/images/loader.gif";
import { LuUpload } from "react-icons/lu";
import { IoCloseOutline } from "react-icons/io5";
import Swal from "sweetalert2";
// import DocumentLoader from "../assets/images/";
import {
  AiFillDelete,
  AiFillEdit,
  AiFillFileExcel,
  AiFillMail,
} from "react-icons/ai";
import { BsFileEarmarkPdfFill, BsFileEarmarkWordFill } from "react-icons/bs";
import { MdOutlineAdd } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { BiSolidFileJpg, BiSolidFilePng } from "react-icons/bi";

const showMessage = (msg, type) => {
  const toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    customClass: { container: "toast" },
  });
  toast.fire({
    icon: type,
    title: msg,
    padding: "10px 20px",
  });
};

const UserEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [documnets, setDocumnets] = useState([]);
  const [uploadedFles, setUploadedFles] = useState([]);
  const [file, setFile] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [profile, setProfile] = useState("");
  const [documentDeleteIndex, setDocumentDeleteIndex] = useState();
  const [submitting, setSubmitting] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      accountType: "",
      profile: null,
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string().required("Name is require"),
      firstName: Yup.string().required("First name is require"),
      lastName: Yup.string().required("Last name is require"),
      // email: Yup.string().required("Last name is require"),
    }),
    onSubmit: async (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };
      try {
        const response = await UserService?.updateUserDetails(id, body);
        if (response) {
          showMessage("User updated successfully", "success");
        }
      } catch (error) {
        showMessage(error?.response?.data?.message, "error");
      }
    },
  });

  const handleAddDocument = () => {
    setIsAdd(true);
  };

  const handleClickClose = () => {
    setIsAdd(false);
    setFile([]);
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    setFile((prev) => [...prev, ...files]); // Set the array of files to Formik's field
    files?.forEach((res) => setDocumnets((prev) => [...prev, res]));
  };

  const handleDrop = async (e, type) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setFile((prev) => [...prev, ...files]); // Set the array of files to Formik's field
    files?.forEach((res) => setDocumnets((prev) => [...prev, res]));
  };

  const getUserDetails = async () => {
    try {
      setIsLoading(true);
      const response = await UserService.getUserDetails(id);
      //   setData(response.data.data);
      formik.setFieldValue("name", response.data.data?.name);
      if (response.data.data?.firstName) {
        formik.setFieldValue("firstName", response.data.data?.firstName);
      }
      if (response.data.data?.lastName) {
        formik.setFieldValue("lastName", response.data.data?.lastName);
      }
      formik.setFieldValue("email", response.data.data?.email);
      formik.setFieldValue("accountType", response.data.data?.accountType);
      if (response.data.data?.latestProfileImage) {
        setProfile(response.data.data?.latestProfileImage);
      }
      if (response.data.data?.incomedocuments) {
        setDocumnets(response.data.data?.incomedocuments);
        setUploadedFles(response.data.data?.incomedocuments);
      }
    } catch (error) {
      showMessage(error?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getUserDetails();
    }
  }, [id]);

  const deleteDocument = async (index) => {
    setDeleteLoader(true);
    setDocumentDeleteIndex(index);
    const files = uploadedFles.filter((_, i) => i === index);
    const formData = new FormData();
    files.map((file) => formData.append("deletedDocuments", file?.document));
    try {
      const response = await UserService.addDocuments(formData, id);
      if (response) {
        getUserDetails();
      }
    } catch (error) {
    } finally {
      setDocumentDeleteIndex("");
      setDeleteLoader(false);
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const handleDocumentSubmit = async () => {
    setSubmitting(true);
    const formData = new FormData();
    documnets.map((item) => formData.append("document", item));
    // formData.append("document", file);
    // formData.append("userId ", id);
    try {
      const response = await UserService?.addDocuments(formData, id);
      if (response) {
        showMessage("File Uploaded Successfully!", "success");
        getUserDetails();
      }
    } catch (error) {
      console.log("🚀  handleDocumentSubmit  error:", error);
      showMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    } finally {
      setFile([]);
      setIsAdd(false);
      setSubmitting(false);
    }
  };
  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[calc(100vh-180px)]">
          <img src={Loader} alt="Loader" className="w-14 h-14" />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-[30%_70%] gap-4">
          <div className="">
            <div className="panel overflow-hidden  max-w-[500px] w-full">
              <div className="tab:px-26 px-4 py-4 tab:py-5 w-full">
                <form onSubmit={formik.handleSubmit}>
                  <div className="flex justify-between items-center flex-col pb-6 gap-4 w-full">
                    <h5 className="tab:text-xl text-base font-semibold text-start pb-2 w-full">
                      Update User Details
                    </h5>
                    {/* <div className="w-full">
                    <label
                      htmlFor="profiles"
                      className="h-[120px] w-[120px] rounded-full relative group overflow-hidden mx-auto"
                    >
                      {profile ? (
                        <img
                          src={profile}
                          alt="User"
                          className="w-full h-full object-cover bg-center"
                        />
                      ) : (
                        <FaUserCircle
                          size={18}
                          className="w-full h-full object-cover bg-primary text-white"
                        />
                      )}
                      <label
                        htmlFor="profiles"
                        className="h-[120px] w-full z-10 bg-black/40 group-hover:flex items-center justify-center hidden absolute top-0 left-0 rounded-full cursor-pointer"
                      >
                        <MdEdit className="h-6 w-6 text-white" />
                      </label>
                    </label>
                    <input
                      type="file"
                      id="profiles"
                      name="profile"
                      className="hidden"
                      // hidden
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          const file = e.target.files[0];
                          setProfile(URL.createObjectURL(file));
                          formik.setFieldValue("profile", file);
                        }
                      }}
                    />
                  </div> */}
                    <div className="w-full pb-4">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-input"
                        placeholder="Enter Name"
                        name="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <div className="text-red-600">
                          {formik.errors.firstName}
                        </div>
                      )}
                    </div>
                    <div className="w-full pb-4">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-input"
                        placeholder="Enter Name"
                        onBlur={formik.handleBlur}
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                      {formik.touched.lastName && formik.errors.lastName && (
                        <div className="text-red-600">
                          {formik.errors.lastName}
                        </div>
                      )}
                    </div>
                    <div className="w-full pb-4">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-input"
                        // readOnly={true}
                        placeholder="Enter Email"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                    </div>
                    <div className="w-full pb-4">
                      <label>Account Type</label>
                      <input
                        type="text"
                        className="form-input"
                        readOnly={true}
                        //   placeholder="Enter Account Type"
                        name="accountType"
                        onChange={formik.handleChange}
                        value={formik.values.accountType}
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <button
                      type="button"
                      onClick={() => navigate("/user")}
                      className="btn bg-white text-danger font-bold border border-danger hover:bg-danger hover:text-white duration-200 text-sm w-full"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn bg-primary text-white font-bold border border-primary hover:bg-transparent hover:text-primary duration-200 text-sm w-full"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="panel h-fit w-full">
            <div className="py-5 tab:py-26 b-b">
              <div className="flex justify-between gap-2 items-center tab:items-start pb-[26px]">
                <h6 className="text-sm tab:text-lg">Income Details</h6>
                <button
                  className="border border-dark rounded-lg flex items-center gap-1 bg-primary text-white font-bold hover:bg-transparent hover:text-primary duration-200 text-sm px-5 tab:px-26 py-2.5 leading-none group"
                  onClick={handleAddDocument}
                >
                  <MdOutlineAdd className=" text-base " />
                  Add Documents
                </button>
              </div>
              <div className="grid grid-cols-1 tab:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {uploadedFles?.length > 0 ? (
                  uploadedFles?.map((file, index) => {
                    return (
                      <div
                        className="flex justify-between items-start border border-primary/20 w-full rounded-lg p-2.5"
                        key={index}
                      >
                        <div className="flex justify-start items-start gap-2.5">
                          <div
                            className={`${
                              file.name?.endsWith(".pdf")
                                ? "text-red-500"
                                : file.name?.endsWith(".xls") ||
                                  file.name?.endsWith(".xlsx")
                                ? "text-green-500"
                                : file.name?.endsWith(".jpg") ||
                                  file.name?.endsWith(".jpeg")
                                ? "text-purple-400"
                                : file.name?.endsWith(".png") ||
                                  file.name?.endsWith(".png")
                                ? "text-yellow"
                                : "text-blue"
                            } text-2xl`}
                          >
                            {file.name?.endsWith(".pdf") ? (
                              <BsFileEarmarkPdfFill />
                            ) : file.name?.endsWith(".xls") ||
                              file.name?.endsWith(".xlsx") ? (
                              <AiFillFileExcel />
                            ) : file.name?.endsWith(".jpg") ||
                              file.name?.endsWith(".jpeg") ? (
                              <BiSolidFileJpg />
                            ) : file.name?.endsWith(".png") ||
                              file.name?.endsWith(".png") ? (
                              <BiSolidFilePng />
                            ) : (
                              <BsFileEarmarkWordFill />
                            )}
                          </div>
                          <div className="">
                            <h6
                              className="text-base break-all line-clamp-1 max-w-[180px]"
                              title={file.name}
                            >
                              {file.name}
                            </h6>
                            <p className="text-sm">
                              {formatFileSize(file.size)}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col items-end">
                          {deleteLoader ? (
                            <>
                              {documentDeleteIndex === index ? (
                                <img
                                  src={Loader}
                                  alt="Loader"
                                  className="w-full h-4"
                                />
                              ) : (
                                <IoMdCloseCircle
                                  className="h-4 w-4 text-primary/40 cursor-pointer"
                                  onClick={() => deleteDocument(index)}
                                />
                              )}
                            </>
                          ) : (
                            <IoMdCloseCircle
                              className="h-4 w-4 text-primary/40 cursor-pointer"
                              onClick={() => deleteDocument(index)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>Documents Not Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isAdd && (
        <div>
          <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="dashboard-card overflow-hidden max-w-[652px] w-full min-w-[350px] bg-white rounded-xl">
                <div className="b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5">
                  <div className="flex justify-between items-center">
                    <h5 className="tab:text-xl text-sm">Add Income Document</h5>
                    <div className="cursor-pointer" onClick={handleClickClose}>
                      <IoCloseOutline className="h-4 w-4" />
                    </div>
                  </div>
                </div>
                <hr className="" />
                <div className="tab:px-26 px-4 pt-4 tab:pt-26">
                  <div className="flex flex-col gap-4">
                    <p className="text-14">
                      Upload documents that show your participation in
                      government assistance programs such as: SNAP food stamps,
                      Section 8 housing, Medicaid, SSI disability, TANF, etc…
                    </p>

                    <div
                      className=""
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => handleDrop(e, "document")}
                    >
                      <label
                        className="border-2 mb-0 border-dashed border-primary/20 rounded-md w-full h-24 tab:h-[101px] flex flex-col items-center justify-center gap-1.5 cursor-pointer"
                        htmlFor="bill-upload"
                      >
                        {submitting ? (
                          <img src={Loader} alt="Loader" className="h-[80px]" />
                        ) : (
                          <>
                            <div className="h-6 w-6">
                              <LuUpload className="h-6 w-6" />
                            </div>
                            <h6 className="text-xs font-normal">
                              Browse or Drag income documents to upload
                            </h6>
                          </>
                        )}
                      </label>
                      <input
                        id="bill-upload"
                        type="file"
                        className="hidden"
                        onChange={handleFileUpload}
                        multiple
                      />
                    </div>

                    {file?.length > 0 &&
                      file.map((res) => (
                        <div key={res.name} className="mt-2">
                          <div className="flex flex-col items-center">
                            <div className="flex items-center">
                              {console.log("res?.name", res)}
                              <span
                                className={`${
                                  res?.name?.endsWith(".pdf")
                                    ? "text-red-500"
                                    : res?.name?.endsWith(".xls") ||
                                      res?.name?.endsWith(".xlsx")
                                    ? "text-green-500"
                                    : res?.name?.endsWith(".jpg") ||
                                      res?.name?.endsWith(".jpeg")
                                    ? "text-purple-400"
                                    : res?.name?.endsWith(".png")
                                    ? "text-yellow"
                                    : "text-blue"
                                } mr-2 text-xl`}
                              >
                                {res?.name?.endsWith(".pdf") ? (
                                  <BsFileEarmarkPdfFill />
                                ) : res?.name?.endsWith(".xls") ||
                                  res?.name?.endsWith(".xlsx") ? (
                                  <AiFillFileExcel />
                                ) : res?.name?.endsWith(".jpg") ||
                                  res?.name?.endsWith(".jpeg") ? (
                                  <BiSolidFileJpg />
                                ) : res?.name?.endsWith(".png") ? (
                                  <BiSolidFilePng />
                                ) : (
                                  <BsFileEarmarkWordFill />
                                )}
                              </span>
                              <span className="text-16 text-primary font-bold">
                                {res?.name}
                              </span>
                              <span className="ml-2 text-primary text-14">
                                Uploaded ({formatFileSize(res.size)})
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="b-b border-primary/20 pb-26"></div>
                <div className="text-end tab:py-5 tab:px-26 py-4 px-4 flex justify-end items-center">
                  <button
                    onClick={handleDocumentSubmit}
                    disabled={submitting}
                    // type="submit"
                    className="border border-dark rounded-lg flex items-center justify-end gap-1 bg-primary text-white font-bold hover:bg-transparent hover:text-primary duration-200 text-sm px-5 tab:px-26 py-2.5 leading-none group "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserEdit;
