import React from "react";

const IconQuestion = ({ className, fill = false, duotone = true }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <circle
          opacity="0.5"
          cx="12"
          cy="12"
          r="10"
          strokeWidth="1.5"
          stroke="currentColor"
        ></circle>{" "}
        <path
          d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13"
          strokeLinecap="round"
          strokeWidth="2"
          stroke="currentColor"
        ></path>{" "}
        <circle
          cx="12"
          cy="16"
          r="1"
          fill="none"
          strokeWidth="1"
          stroke="currentColor"
        ></circle>{" "}
      </g>
    </svg>
  );
};

export default IconQuestion;
