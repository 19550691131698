import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

const UserTypeDropdown = ({ options, value, onChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption);
    setShowDropdown(false);
  };

  return (
    <div className="relative w-48">
      <div
        className="flex items-center justify-between p-2 border rounded cursor-pointer"
        onClick={toggleDropdown}
      >
        <span className="font-bold">{value}</span>
        <IoMdArrowDropdown
          className={`duration-300 ${showDropdown ? "rotate-180" : "rotate-0"}`}
        />
      </div>

      {showDropdown && (
        <div className="absolute top-full left-0 mt-1 w-full rounded shadow bg-white z-50">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => handleOptionClick(option.label)}
              className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserTypeDropdown;
