import React, { useEffect, useState } from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import DocumentModal from "../components/DocumentModal";
import UserService from "../service/user.service";
import Avatar from "react-avatar";
import DropdownCustom from "../components/DropdownCustom";
import Swal from "sweetalert2";
import Loader from "../assets/images/loader.gif";
import { MdOutlineAdd } from "react-icons/md";
import { LuUpload } from "react-icons/lu";

const UserDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  console.log("🚀  UserDetails  data:", data);
  const [isDelete, setIsDelete] = useState(false);
  const [deletingVideo, setDeletingVideo] = useState(null); // Track the video being deleted
  const [documentModal, setDocumentModal] = useState();
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState("");
  const [selectedPayyroStatus, setSelectedPayyroStatus] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [videoLoader, setVideoLoader] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [imageLoader, setImageLoader] = useState(false);

  const handleDocumentOpen = (file) => {
    setDocumentModal(true);
    setSelectedFile(file);
  };

  const getUserDetails = async () => {
    try {
      const response = await UserService.getUserDetails(id);
      setData(response.data.data);
      console.log("getUserDetails", response.data.data);
      setSelectedDocumentStatus(response.data.data.statusOfIncomeDocument);
      setSelectedPayyroStatus(response.data.data.statusOfPayyro);
      setSelectedImages(response.data.data.profileImages);
    } catch (error) {
      setData([]);
      showMessage(error?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const updatePayyroStatus = async (status) => {
    try {
      const response = await UserService.updatePayyroStatus(id, status);
      console.log("updatePayyroStatus", response);
      getUserDetails();
    } catch (error) {
      showMessage(error?.response?.data?.message, "error");
    }
  };

  const updateDocumentStatus = async (status) => {
    try {
      const response = await UserService.updateDocumentStatus(id, status);
      console.log("updateDocumentStatus", response);
      getUserDetails();
    } catch (error) {
      showMessage(error?.response?.data?.message, "error");
    }
  };

  const showMessage = (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  useEffect(() => {
    getUserDetails();
  }, [id]);

  const status = [
    { label: "Pending", value: "Pending" },
    { label: "Approve", value: "Approve" },
    { label: "Rejected", value: "Rejected" },
  ];

  /* const handleDeleteVideo = async (url) => {
    setDeletingVideo(url);
    setIsDelete(true);
    try {
      const payload = {
        url,
        id,
      };
      const response = await UserService?.deleteVideo(payload);
      if (response) {
        showMessage(
          response.data.message || "video deleted succeessfully",
          "success"
        );
        getUserDetails();
      }
    } catch (error) {
      showMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    } finally {
      setDeletingVideo("");
      setIsDelete(false);
    }
  }; */

  const handleDeleteVideo = async (url) => {
    setDeletingVideo(url);
    setIsDelete(true);

    // Optimistically update the UI
    const updatedVideos = data.video.filter((video) => video !== url);
    setData((prev) => ({
      ...prev,
      video: updatedVideos,
    }));

    try {
      const payload = {
        url,
        id,
      };
      const response = await UserService?.deleteVideo(payload);
      if (response) {
        showMessage(
          response.data.message || "Video deleted successfully",
          "success"
        );

        // Optionally refresh the user details to ensure data consistency
        getUserDetails();
      }
    } catch (error) {
      showMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );

      // Revert the UI if the deletion fails
      setData((prev) => ({
        ...prev,
        video: [...prev.video, url],
      }));
    } finally {
      setDeletingVideo("");
      setIsDelete(false);
    }
  };

  const handleDeleteProfileImages = async (url) => {
    setDeleteLoader(true);
    try {
      const payload = {
        url,
        id,
      };
      const response = await UserService?.deleteProfileImages(payload);
      if (response) {
        getUserDetails();
        showMessage(
          /* response.data.message || */ "profile image deleted succeessfully",
          "success"
        );
      }
    } catch (error) {
      showMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    } finally {
      setDeleteLoader(false);
    }
  };

  const handleEditVideo = async (e) => {
    const files = Array.from(e.target.files);
    if (data?.video?.length > 2) {
      showMessage(`You can add up to 3 videos only.`, "error");
      return;
    }
    files.forEach((file) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(video.src);
        if (video.duration <= 60) {
          setVideoLoader(true);
          const formData = new FormData();
          formData.append("video", file);

          try {
            const response = await UserService.addVideo(formData, data?._id);
            showMessage(
              response.data.message || "video added succeessfully",
              "success"
            );
            getUserDetails();
          } catch (error) {
            console.log("🚀  error:", error);
            showMessage(
              error.response.data.message || "Something went wrong",
              "error"
            );
          } finally {
            setVideoLoader(false);
          }
        } else {
          showMessage(
            `${file.name} is longer than 60 seconds and cannot be uploaded.`,
            "error"
          );
        }
      };

      video.src = URL.createObjectURL(file);
    });

    // Clear the input so the same file can be selected again if needed
    e.target.value = "";
  };

  const processImages = async (files) => {
    if (selectedImages.length + files.length > 5) {
      showMessage("Maximum 5 images are allowed", "error");
      return;
    }

    setImageLoader(true);
    const formData = new FormData();
    formData.append("userId", id);
    files.forEach((img) => formData.append("profileImages", img));

    try {
      const response = await UserService.addProfileImages(formData);
      getUserDetails();
      showMessage(
        response.data.message || "video added succeessfully",
        "success"
      );
    } catch (error) {
      showMessage(
        error.response.data.message || "Something went wrong",
        "error"
      );
    } finally {
      setImageLoader(false);
    }

    const fileURLs = files.map((img) => URL.createObjectURL(img));
    setSelectedImages((prev) => [...prev, ...fileURLs]);
  };

  const handleChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    await processImages(files);
  };

  const handleDrop = async (e, type) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);

    if (type === "image") {
      await processImages(files);
    }
  };

  /*   const deleteImages = async (index) => {
    setDeleteLoader(true);
    setDeleteIndex(index);
    const images = selectedImages.filter((_, i) => i === index);

    const formData = new FormData();
    formData.append("userId", id);
    images.map((img) => formData.append("deletedImages", img));
    try {
      const response = await UserService.addProfileImages(formData);
      getUserDetails();
    } catch (error) {
      showMessage(error?.response?.data?.message, "error");
    } finally {
      setDeleteLoader(false);
      setDeleteIndex("");
    }
  }; */

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[calc(100vh-180px)]">
          <img src={Loader} alt="Loader" className="w-14 h-14" />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="panel">
            <div className="pb-5 flex justify-between items-center border-b border-primary/20">
              <h5 className="text-lg font-semibold dark:text-white-light">
                User Profile
              </h5>

              {selectedPayyroStatus && (
                <DropdownCustom
                  data={status}
                  width={"100%"}
                  name="Select Status"
                  setData={setSelectedPayyroStatus}
                  defaultData={selectedPayyroStatus}
                  updatePayyroStatus={updatePayyroStatus}
                  type="payyro"
                />
              )}
            </div>
            <div className="py-5 border-primary/20">
              <div className="flex items-center gap-4 pb-7">
                <div className="h-[75px] w-[75px] md:h-20 md:w-20 rounded-lg overflow-hidden avatar">
                  {data?.latestProfileImage ? (
                    <img
                      src={data?.latestProfileImage}
                      alt="User"
                      className="object-cover bg-cover bg-center w-full h-full"
                    />
                  ) : (
                    <Avatar
                      size={75}
                      name={data?.firstName || data?.name}
                      className="rounded-lg overflow-hidden"
                    />
                  )}
                </div>

                <h5 className="text-base font-semibold dark:text-white-light">
                  {/* {data?.name} */}
                  {data?.firstName ? (
                    <>
                      {data?.firstName} {data?.lastName}
                    </>
                  ) : (
                    <>{data?.name}</>
                  )}
                </h5>
              </div>
              <div>
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Name</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.firstName ? (
                      <>
                        {data?.firstName} {data?.lastName}
                      </>
                    ) : (
                      <>{data?.name}</>
                    )}
                  </p>
                </div>
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Email</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.email}
                  </p>
                </div>
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Account Type</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.accountType}
                  </p>
                </div>
                {data?.address && (
                  <div className="pb-4 grid sm:grid-cols-3 gap-1">
                    <span className="font-semibold">Address</span>
                    <p className="col-span-2">
                      <strong className="hidden sm:inline-block">: </strong>{" "}
                      {data?.address}
                    </p>
                  </div>
                )}
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Profile Completed</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.isCompleteProfile ? "Yes" : "No"}
                  </p>
                </div>
                {data?.accountNumber && (
                  <div className="pb-4 grid sm:grid-cols-3 gap-1">
                    <span className="font-semibold">Acount No.</span>
                    <p className="col-span-2">
                      <strong className="hidden sm:inline-block">: </strong>{" "}
                      {data?.accountNumber}
                    </p>
                  </div>
                )}
                {data?.bankName && (
                  <div className="grid sm:grid-cols-3 gap-1">
                    <span className="font-semibold">Bank Name</span>
                    <p className="col-span-2">
                      <strong className="hidden sm:inline-block">: </strong>{" "}
                      {data?.bankName}
                    </p>
                  </div>
                )}
                <>
                  <>
                    <hr className="my-5" />
                    <div className="flex justify-between gap-4 items-center tab:items-start">
                      <div>
                        <h6 className="text-sm tab:text-lg font-semibold">
                          My video for Donors in support of my Help Requests
                        </h6>
                        <p>(Max. 60 seconds allowed)</p>
                      </div>
                      {videoLoader ? (
                        <div className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 !h-[37.6px] !w-[144.2px] leading-none group cursor-pointer justify-center">
                          <img src={Loader} alt="Loader" className="w-8 h-8" />
                        </div>
                      ) : (
                        <label
                          htmlFor="uploadvideos"
                          className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group cursor-pointer border-2 border-gray-700 rounded-3xl"
                        >
                          <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
                          Add Video
                        </label>
                      )}
                      <input
                        type="file"
                        onChange={(e) => handleEditVideo(e)}
                        hidden
                        id="uploadvideos"
                        accept="video/*"
                      />
                    </div>
                  </>
                </>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mt-4">
                  {data.video?.length > 0 &&
                    data.video?.map((res) => {
                      return (
                        <>
                          <div className="relative group h-[185px]">
                            {isDelete && deletingVideo === res ? (
                              <div className="flex justify-center items-center h-full">
                                <img
                                  src={Loader}
                                  alt="Loader"
                                  className="w-8 h-8"
                                />
                              </div>
                            ) : (
                              <>
                                <video
                                  autoPlay
                                  controls
                                  loop
                                  className=" rounded-lg object-cover w-full h-full"
                                >
                                  <source src={res} />
                                </video>
                                <div
                                  className="absolute top-1 right-3 cursor-pointer h-7 w-7 flex items-center justify-center border border-primary/20 rounded bg-white opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDeleteVideo(res);
                                  }}
                                >
                                  <AiFillDelete className="h-5 w-5 hover:text-danger/70 text-danger duration-300" />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      );
                    })}
                </div>
                {data.video?.length === 0 && (
                  <div className="flex justify-center items-center h-10">
                    Video not found
                  </div>
                )}

                <hr className="mt-5" />
                <div className="py-5 tab:py-26 b-b">
                  <div className="flex justify-between gap-2 items-center tab:items-start pb-26">
                    <div>
                      <h6 className="text-sm tab:text-lg font-semibold">
                        Profile Images
                      </h6>
                      <p className="text-xs tab:text-13">
                        (Max. 5 images allowed)
                      </p>
                    </div>
                    {imageLoader ? (
                      <div className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 !h-[37.16px] !w-[147.58px] leading-none group cursor-pointer justify-center">
                        <img src={Loader} alt="Loader" className="w-8 h-8" />
                      </div>
                    ) : (
                      <label
                        htmlFor="uploadprofile"
                        className="btn-default border-2 border-gray-700 rounded-3xl btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group cursor-pointer"
                        type="button"
                      >
                        <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
                        Add Image
                      </label>
                    )}
                  </div>
                  <div className="flex justify-start items-center gap-4 mt-5">
                    {selectedImages &&
                      selectedImages?.map((img, index) => {
                        return (
                          <div
                            className="w-[130px] h-[130px] relative"
                            key={index}
                          >
                            <img
                              src={img}
                              alt=""
                              className="rounded-[10px] w-full h-full object-cover"
                            />
                            <div
                              className="w-8 h-8 bg-white/80 absolute top-3 right-4 rounded-[10px] cursor-pointer flex justify-center items-center"
                              // onClick={() => deleteImages(index)}
                              onClick={() => handleDeleteProfileImages(img)}
                            >
                              {deleteLoader ? (
                                <>
                                  {deleteIndex === index ? (
                                    <img src={Loader} alt="Loader" />
                                  ) : (
                                    <AiFillDelete className="text-red-500 text-[19px] content-center" />
                                  )}
                                </>
                              ) : (
                                <AiFillDelete className="text-red-500 text-[19px] content-center " />
                              )}
                            </div>
                          </div>
                        );
                      })}

                    {selectedImages.length < 5 && (
                      <div
                        className="bg-primary/10 w-[130px] h-[130px] rounded-[10px] flex items-center p-1 "
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e, "image")}
                      >
                        <div className="mx-auto">
                          <label
                            htmlFor="uploadprofile"
                            className="w-full flex flex-col justify-center items-center"
                          >
                            <div className="m-4 mt-0 w-8 h-8 bg-white/60 rounded-lg flex justify-center items-center">
                              <LuUpload className="text-17" />
                            </div>
                            <div className="text-center">
                              Browse or Drag image to upload
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                    <input
                      type="file"
                      multiple
                      onChange={(e) => handleChangeFile(e)}
                      hidden
                      id="uploadprofile"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pt-5">
              <div className="flex justify-between mb-4">
                <h5 className="text-base font-semibold dark:text-white-light">
                  Documents
                </h5>
                {data?.incomedocuments.length > 0 && (
                  <div className="flex gap-3">
                    {selectedDocumentStatus && (
                      <DropdownCustom
                        data={status}
                        width={"100%"}
                        name="Select Status"
                        setData={setSelectedDocumentStatus}
                        defaultData={selectedDocumentStatus}
                        updateDocumentStatus={updateDocumentStatus}
                        type="document"
                      />
                    )}
                  </div>
                )}
              </div>
              {data?.incomedocuments.length > 0 ? (
                data?.incomedocuments?.map((item, index) => {
                  return (
                    <div
                      className="flex items-center justify-between gap-4 my-3"
                      key={index}
                    >
                      <span className="line-clamp-2">{item.name}</span>

                      <div
                        onClick={() => handleDocumentOpen(item.document)}
                        className="border border-primary/20 rounded p-1 cursor-pointer duration-300 hover:bg-primary hover:border-primary group"
                      >
                        <AiFillEye className="h-4 w-4 text-primary/80 group-hover:text-white" />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>Documents Not Found</div>
              )}
            </div> */}

            {documentModal && (
              <DocumentModal
                open={documentModal}
                handleClickClose={() => setDocumentModal(false)}
                selectedFile={selectedFile}
              />
            )}
          </div>
          <div className="panel w-full h-fit">
            <div className="flex justify-between mb-4">
              <h5 className="text-base font-semibold dark:text-white-light">
                Documents
              </h5>
              {data?.incomedocuments && data?.incomedocuments.length > 0 && (
                <div className="flex gap-3">
                  {selectedDocumentStatus && (
                    <DropdownCustom
                      data={status}
                      width={"100%"}
                      name="Select Status"
                      setData={setSelectedDocumentStatus}
                      defaultData={selectedDocumentStatus}
                      updateDocumentStatus={updateDocumentStatus}
                      type="document"
                    />
                  )}
                </div>
              )}
            </div>
            {data?.incomedocuments && data?.incomedocuments.length > 0 ? (
              data?.incomedocuments?.map((item, index) => {
                return (
                  <div
                    className="flex items-center justify-between gap-4 my-3"
                    key={index}
                  >
                    <span className="line-clamp-2">{item.name}</span>

                    <div
                      onClick={() => handleDocumentOpen(item.document)}
                      className="border border-primary/20 rounded p-1 cursor-pointer duration-300 hover:bg-primary hover:border-primary group"
                    >
                      <AiFillEye className="h-4 w-4 text-primary/80 group-hover:text-white" />
                    </div>
                  </div>
                );
              })
            ) : (
              <div>Documents Not Found</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
