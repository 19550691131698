import React, { useEffect, useState } from "react";
import IconPlus from "../../components/Icon/IconPlus";
import CustomPagination from "../../components/Layouts/CustomPagination";
import { DataTable } from "mantine-datatable";
import { AiFillDelete } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import IconEdit from "../../components/Icon/IconEdit";
import HelpRequestService from "../../service/helpRequest.service";
import { useLocation, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

const DetailHelpRequests = () => {
  const location = useLocation();

  // Access the passed state
  const { id, type, additionalInfo } = location.state || {};
  console.log("🚀  DetailHelpRequests  additionalInfo:", additionalInfo);
  const [searchText, setSearchText] = useState("");
  const [value] = useDebounce(searchText, 1000);
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  console.log("🚀  DetailHelpRequests  data:", data);
  const [isLoading, setIsLoading] = useState(true);

  const handleDocumentOpen = (type, id, name) => {
    if (type === "add" || type === "edit") {
      setDocumentModal(true);

      if (id) {
        formik.setFieldValue("name", name);
        formik.setFieldValue("id", id);
        setIsUpdate(true);
      } else {
        formik.resetForm();
        setIsUpdate(false);
      }
    } else {
      setDeleteId(id);
      setDeleteModals(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please fill the name"),
    }),
    onSubmit: async (values) => {
      console.log("values", values);
      const body = {
        categoryId: values.id,
        name: values.name,
      };
      try {
        if (!values.id) {
          const response = await CategoryService.addCategory(values.name);
          console.log("addCategory", response);
          showMessage(response.data.message, "success");
        } else {
          const response = await CategoryService.updateCategory(body);
          showMessage(response.data.message, "success");
        }
        getAllCategory();
        setIsUpdate(false);
        handleClickClose("add");
        formik.resetForm();
      } catch (error) {
        showMessage(error?.response?.data?.message, "error");
      }
    },
  });

  const handleClickClose = (type) => {
    if (type === "add") {
      setIsUpdate(false);
      formik.resetForm();
      setDocumentModal(false);
    } else {
      setDeleteModals(false);
    }
  };

  const showMessage = (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  const handleChange = (type, page) => {
    const total = totalCount / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const getAllCategory = async () => {
    setIsLoading(true);
    try {
      const response = await HelpRequestService?.getHelpDetails(
        page,
        pageSize,
        value,
        id
      );
      console.log("getAllCategory", response);
      if (response) {
        setData(response.data.data);
        setTotalCount(response.data.total);
      }
    } catch (error) {
      showMessage(error?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getAllCategory();
    }
  }, [id, value, page, pageSize]);

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  const generateColumns = (fields, userType) => {
    // Filter or modify fields based on user type
    const filteredFields = fields.filter((field) => {
      if (userType === "User") {
        return field.showForUser;
      } else if (userType === "Vendor") {
        return field.showForVendor;
      }
      return true; // Default case
    });

    // Map through the filtered fields to create columns
    return filteredFields.map((field) => {
      switch (field.accessor) {
        case "id":
          return {
            accessor: field.accessor,
            title: field.title || "ID",
            render: (row, index) => (
              <div>
                <strong>{index + 1}</strong>
              </div>
            ),
          };
        case "name":
          return {
            accessor: field.accessor,
            title: field.title || "Name",
            render: ({ userId }) => (
              <div className="w-full">
                <p>{userId?.name || userId?.firstName}</p>
              </div>
            ),
          };
        case "email":
          return {
            accessor: field.accessor,
            title: field.title || "Email",
            render: ({ userId }) => (
              <div className="w-full">
                <p>{userId?.email}</p>
              </div>
            ),
          };
        case "currency":
          return {
            accessor: field.accessor,
            title: field.title || "Currency",
            render: ({ currency }) => (
              <div className="w-full">
                <p>{currency === "doller" ? "USD" : currency}</p>
              </div>
            ),
          };
        case "amount":
          return {
            accessor: field.accessor,
            title: field.title || "Amount",
            render: ({ amount }) => (
              <div className="w-full">
                <p>{amount}</p>
              </div>
            ),
          };
        default:
          return {
            accessor: field.accessor,
            title: field.title || field.accessor,
            render: (row) => (
              <div className="w-full">
                <p>{row[field.accessor]}</p>
              </div>
            ),
          };
      }
    });
  };

  // Dynamic fields with user type conditions
  const fields = [
    { accessor: "id", title: "ID", showForUser: true, showForVendor: true },
    { accessor: "name", title: "Name", showForUser: true, showForVendor: true },
    {
      accessor: "email",
      title: "Email",
      showForUser: true,
      showForVendor: true,
    },
    {
      accessor: "helpRequestId",
      title: "Help Request ID",
      showForUser: true,
      showForVendor: true,
    },
    {
      accessor: "currency",
      title: "Currency",
      showForUser: true,
      showForVendor: true,
    },
    {
      accessor: "amount",
      title: "Amount",
      showForUser: true,
      showForVendor: true,
    },
  ];

  const columns = generateColumns(fields, type);

  return (
    <>
      <div className="panel w-full">
        <div className="flex items-center justify-between gap-4 border-b border-primary/20 pb-5">
          <h5 className="text-lg font-semibold dark:text-white-light">
            Help requests
          </h5>
          <input
            className=" border border-primary/15 w-[300px] rounded-lg py-3 ps-3 pe-8  text-sm focus:outline-none focus:ring-[0.4px] focus:ring-primary h-10 overflow-hidden placeholder:text-primary/70"
            placeholder="Search Help Requests"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="datatables user-table">
          <DataTable
            noRecordsText="No results match your search query"
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={data}
            columns={columns}
            totalRecords={totalCount}
            recordsPerPage={pageSize}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            minHeight={200}
            fetching={isLoading}
            loading={isLoading}
          />
          {data?.length > 0 && (
            <CustomPagination
              total={totalCount}
              page={page}
              handleChange={handleChange}
              dataPerPage={pageSize}
              handleDataPerPage={handleDataPerPage}
              PAGE_SIZES={PAGE_SIZES}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DetailHelpRequests;
