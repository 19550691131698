import serverCall from "../serverCall";

const getAllHelp = (page, size, search, type) => {
  try {
    const response = serverCall.post(
      `/help-request/admin/get-help-request?page=${page}&size=${size}&search=${search}&type=${type}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const getHelpDetails = (page, size, search, id) => {
  try {
    const response = serverCall.post(
      `/help-request/admin/get-help-transaction?id=${id}&page=${page}&size=${size}&search=${search}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const HelpRequestService = {
  getAllHelp,
  getHelpDetails,
};

export default HelpRequestService;
