import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../store/themeConfigSlice";
import { useState, useEffect } from "react";
import IconMenuChat from "../Icon/Menu/IconMenuChat";
import IconMenuUsers from "../Icon/Menu/IconMenuUsers";
import IconLogout from "../Icon/IconLogout";
import logoImage from "../../assets/images/logo.svg";
import logoImageContent from "../../assets/images/logo-text.svg";
import IconQuestion from "../Icon/IconQuestion";
import { GrTransaction } from "react-icons/gr";
import { FaHandsHelping } from "react-icons/fa";

const Sidebar = () => {
  const navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState("");
  const [errorSubMenu, setErrorSubMenu] = useState(false);
  const themeConfig = useSelector((state) => state.themeConfig);
  const semidark = useSelector((state) => state.themeConfig.semidark);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleMenu = (value) => {
    setCurrentMenu((oldValue) => {
      return oldValue === value ? "" : value;
    });
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const selector = document.querySelector(
      '.sidebar ul a[href="' + window?.location.pathname + '"]'
    );
    if (selector) {
      selector.classList.add("active");
      const ul = selector.closest("ul.sub-menu");
      if (ul) {
        let ele = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024 && themeConfig.sidebar) {
      dispatch(toggleSidebar());
    }
    // eslint-disable-next-line
  }, [location]);

  const handleLogout = () => {
    localStorage.removeItem("payyro-admin-store");
    window.location.reload();
    navigate("/login");
  };

  return (
    <div className={semidark ? "dark" : ""}>
      <nav
        className={` sidebar fixed min-h-screen overflow-hidden h-full top-0 bottom-0 w-[260px] z-50 transition-all duration-300 border-r border-[#E2E2E2] ${
          semidark ? "text-white-dark" : ""
        }`}
      >
        <div className="h-full">
          <div className="flex justify-between items-center px-4 py-3">
            <NavLink
              to="/user"
              className="main-logo flex items-center gap-3 shrink-0"
            >
              <img
                className="w-8 ml-[5px] flex-none"
                src={logoImage}
                alt="logo"
              />
              {/* <span className="text-xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline dark:text-white-light">
                SLASH STAR
              </span> */}
              <div className="ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline">
                <img
                  className="h-6 ml-[5px] flex-none"
                  src={logoImageContent}
                  alt="logo"
                />
              </div>
            </NavLink>
          </div>
          <PerfectScrollbar className="h-[calc(100vh-50px)] relative">
            <div className="flex flex-col justify-between h-full">
              <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                <li className="nav-item">
                  <ul>
                    <li className="nav-item">
                      <NavLink to="/user" className="group">
                        <div className="flex items-center">
                          <IconMenuUsers className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3  dark:text-[#506690] dark:group-hover:text-white-dark">
                            {/* {t("mailbox")} */}
                            User
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/category" className="group">
                        <div className="flex items-center">
                          <IconMenuChat className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">
                            {/* {t("chat")} */}
                            Category
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/helprequests" className="group">
                        <div className="flex items-center">
                          <FaHandsHelping className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">
                            {/* {t("chat")} */}
                            Help Requests
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/transactions" className="group">
                        <div className="flex items-center">
                          <GrTransaction className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">
                            Transactions
                          </span>
                        </div>
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink to="/faq" className="group">
                        <div className="flex items-center">
                          <IconQuestion className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3  dark:text-[#506690] dark:group-hover:text-white-dark">
                            {/* {t("mailbox")} */}
                            FAQ
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="px-4 py-4">
                <li className="nav-item">
                  <button onClick={handleLogout} className="group text-danger">
                    <div className="flex items-center w-full gap-6">
                      <IconLogout className="rotate-90 shrink-0 !text-danger group-hover:!text-primary" />
                      <span className="text-danger group-hover:text-primary font-semibold">
                        {/* {t("scrumboard")} */}
                        Sign Out
                      </span>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </PerfectScrollbar>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
